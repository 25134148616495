<template>
  <v-container fluid class="text-center">
    <v-row>
      <v-col cols="6">
        <v-card class="elevation-5 mt-5 mx-5">
          <v-toolbar dense dark color="secondary" elevation="2">
            <v-toolbar-title>
              Catálogos
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text small @click="dialogs.new = true">
              Agregar
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-title>
            <v-btn small :loading="loading" elevation="0" color="secondary" outlined class="mx-2" @click="refresh()">
              Actualizar
              <v-icon right>mdi-refresh</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
          </v-card-title>
          <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search"
          :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
            <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template><span>Editar</span></v-tooltip>
              <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="getDetail(item)">mdi-file-table</v-icon></template><span>Ver detalle</span></v-tooltip>
              <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="copy(item.idcatalogo)">mdi-content-duplicate</v-icon></template><span>Copiar</span></v-tooltip>
              <v-tooltip right><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idcatalogo)">mdi-delete</v-icon></template><span>Eliminar</span></v-tooltip>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
            <template v-slot:no-data>
              No hay información disponible
            </template>
            <template v-slot:no-results>
              No se obtuvieron resultados
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col v-if="detail.id" cols="6">
        <v-card class="elevation-5 mt-5 mx-5">
          <v-toolbar dense color="#ffde59" elevation="2">
            <v-toolbar-title>
              {{ detail.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text small :disabled="loading" @click="cancelDetail()">
              Cancelar
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn text small :disabled="loading"  @click="dialogs.import = true">
              Agregar
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
            <v-btn text small :disabled="!detail.values.length" :loading="loading" @click="dialogs.values = true">
              Actualizar
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-toolbar>
          <v-alert class="ma-2" border="left" color="warning" elevation="2" dense  colored-border icon="mdi-alert">
            Al importar un catálogo de tipo <strong>Factor de emisión</strong>, colocar los valores de las emisiones en kilogramos
          </v-alert>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field v-model="detail.search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
          </v-card-title>
          <v-data-table v-model="detail.selected" :headers="detail.headers" :items="detail.items" :loading="loading" :search="detail.search"
          :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [10, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
            <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon class="mr-2" size="22" color="primary" dark :disabled="loading" @click="view(item)">mdi-pencil</v-icon>
              <v-icon class="mr-2" size="22" color="error" dark :disabled="loading" @click="remove(item.idcatalogo)">mdi-delete</v-icon>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
            <template v-slot:no-data>
              No hay información disponible
            </template>
            <template v-slot:no-results>
              No se obtuvieron resultados
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Nuevo catálogo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-va2lidation>
              <v-row>
                <v-col cols="8">
                  <v-text-field v-model="forms.new.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="forms.new.periodo" label="Periodo" :rules="rules.periodo" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete v-model="forms.new.empresa" :items="catalogues.empresas" item-text="nombre" item-value="idempresa" label="Empresa" :disabled="loading" clearable required></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.new.codigo" label="Código" :rules="rules.codigo" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.descripcion" label="Descripción" :rules="rules.descripcion" :disabled="loading" required></v-text-field>
                </v-col>
              </v-row>
              <template v-if="forms.new.columnas.length">
                <v-divider class="ma-5"></v-divider>
                <v-row justify="center">
                  <span>Columnas</span>
                </v-row>
              </template>
              <template v-for="(item, idx) in forms.new.columnas">
                <v-row :key="idx">
                  <v-col cols="4">
                    <v-text-field v-model="item.id" label="Id" :rules="rules.columna.id" :disabled="loading" required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="item.nombre" label="Nombre" :rules="rules.columna.nombre" :disabled="loading" required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete v-model="item.tipo" :items="catalogues.tipos" label="Tipo" :rules="rules.columna.tipo" :disabled="loading" required></v-autocomplete>
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined :disabled="forms.new.columnas.length == 1 || loading" color="primary" class="mx-2" @click="removeColumn()">
            Quitar columna
          </v-btn>
          <v-btn small outlined :disabled="forms.new.columnas.length == 5 || loading" color="primary" class="mx-2" @click="addColumn()">
            Agregar columna
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar catálogo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row>
                <v-col cols="8">
                  <v-text-field v-model="forms.update.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="forms.update.periodo" label="Periodo" :rules="rules.periodo" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete v-model="forms.update.empresa" :items="catalogues.empresas" item-text="nombre" item-value="idempresa" label="Empresa" :disabled="loading" clearable required></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.update.codigo" label="Código" :rules="rules.codigo" :disabled="loading" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.descripcion" label="Descripción" :rules="rules.descripcion" :disabled="loading" required></v-text-field>
                </v-col>
              </v-row>
              <template v-if="forms.update.columnas.length">
                <v-divider class="ma-5"></v-divider>
                <v-row justify="center">
                  <span>Columnas</span>
                </v-row>
              </template>
              <template v-for="(item, idx) in forms.update.columnas">
                <v-row :key="idx">
                  <v-col cols="4">
                    <v-text-field v-model="item.id" label="Id" :rules="rules.columna.id" :disabled="loading" required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="item.nombre" label="Nombre" :rules="rules.columna.nombre" :disabled="loading" required></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete v-model="item.tipo" :items="catalogues.tipos" label="Tipo" :rules="rules.columna.tipo" :disabled="loading" required></v-autocomplete>
                  </v-col>
                </v-row>
              </template>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small outlined :disabled="forms.update.columnas.length == 1 || loading" color="primary" class="mx-2" @click="removeColumn()">
            Quitar columna
          </v-btn>
          <v-btn small outlined :disabled="forms.update.columnas.length == 5 || loading" color="primary" class="mx-2" @click="addColumn()">
            Agregar columna
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.import" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Importar valores</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="accept()">
            Cargar
            <v-icon right>mdi-arrow-up</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formImport" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-file-input v-model="forms.import.id" chips show-size label="Seleccionar archivo..." :accept="catalogues.mime_types" :disabled="loading"></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-switch v-model="forms.import.header" label="Ignorar la primera fila" :disabled="loading"></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.copy" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea copiar el catálogo?</v-card-text>
        <v-card-text>Se actualizará el periodo con el año siguiente</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="success" @click="copy()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar el catálogo?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.values" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea actualizar los valores del catálogo?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="success" @click="updateValues()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services'
import _ from 'lodash'
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse'

export default {
  name: 'catalogue',
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    search: '',
    headers: [
      { text: 'Nombre', align: 'left', sortable: false, value: 'nombre' },
      { text: 'Periodo', align: 'left', sortable: false, value: 'periodo' },
      { text: 'Empresa', align: 'left', sortable: false, value: 'empresa.nombre' },
      { text: 'Código', align: 'left', sortable: false, value: 'codigo' },
      { text: 'Columnas', align: 'left', sortable: false, value: 'columnas.length' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' }
    ],
    items: [],
    selected: [],
    deleted: null,
    copied: null,
    catalogues: {
      tipos: [
        'texto',
        'entero',
        'decimal'
      ],
      mime_types: [
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ],
      empresas: []
    },
    forms: {
      new: {
        nombre: '',
        periodo: '',
        empresa: null,
        codigo: '',
        descripcion: '',
        columnas: [{
          id: '',
          nombre: '',
          tipo: ''
        }]
      },
      update: {
        idcatalogo: '',
        nombre: '',
        periodo: '',
        empresa: null,
        codigo: '',
        descripcion: '',
        columnas: []
      },
      import: {
        id: null,
        header: false
      }
    },
    rules: {
      nombre: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
      ],
      periodo: [
        v => !!v || 'El campo es requerido',
        v => /^\d{4}$/.test(v) || 'El campo es inválido'
      ],
      codigo: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 50) || 'El campo excede la longitud máxima',
        v => /^[a-zA-ZñÑ0-9\_\-]{1,50}$/.test(v) || 'El campo es inválido'
      ],
      descripcion: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 200) || 'El campo excede la longitud máxima'
      ],
      columnas: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 5) || 'El campo excede la longitud máxima'
      ],
      columna: {
        id: [
          v => !!v || 'El campo es requerido',
          v => (v && v.length <= 50) || 'El campo excede la longitud máxima',
          v => /^[a-zA-ZñÑ0-9\_\-]{1,50}$/.test(v) || 'El campo es inválido'
        ],
        nombre: [
          v => !!v || 'El campo es requerido',
          v => (v && v.length <= 100) || 'El campo excede la longitud máxima'
        ],
        tipo: [
          v => !!v || 'El campo es requerido'
        ]
      }
    },
    detail: {
      id: '',
      title: '',
      search: '',
      headers: [],
      items: [],
      selected: [],
      columns: [],
      values: []
    },
    dialogs: {
      new: false,
      update: false,
      import: false,
      copy: false,
      remove: false,
      values: false
    }
  }),
  mounted() {
    this.refresh()
  },
  methods: {
    async refresh() {
      this.loading = true
      const company = await this.axios.get(services.routes.company)
      if (company.data.data) {
        this.catalogues.empresas = company.data.data
      }
      this.axios.get(services.routes.catalogue)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset()
        for (let key in this.forms.new) {
          if (key === 'empresa') {
            this.forms.new[key] = null
          }
          else if (key !== 'columnas') {
            this.forms.new[key] = ''
          }
          else {
            this.forms.new[key] = [{
              id: '',
              nombre: '',
              tipo: ''
            }]
          }
        }
        this.dialogs.new = false
      }
      else if (this.dialogs.update) {
        this.$refs.formUpdate.reset()
        for (let key in this.forms.update) {
          if (key === 'empresa') {
            this.forms.update[key] = null
          }
          else if (key !== 'columnas') {
            this.forms.update[key] = ''
          }
          else {
            this.forms.update[key] = []
          }
        }
        this.dialogs.update = false
      }
      else if (this.dialogs.copy) {
        this.dialogs.copy = false
      }
      else if (this.dialogs.remove) {
        this.dialogs.remove = false
      }
      else if (this.dialogs.import) {
        this.forms.import = {
          id: null,
          header: false
        }
        this.dialogs.import = false
      }
      else if (this.dialogs.values) {
        this.dialogs.values = false
      }
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.new))
        this.axios.post(services.routes.catalogue, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Catálogo creado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    view(data) {
      this.cancelDetail()
      this.forms.update = {
        idcatalogo: data.idcatalogo,
        nombre: data.nombre,
        periodo: data.periodo,
        empresa: (data.empresa) ? data.empresa.idempresa : null,
        codigo: data.codigo,
        descripcion: data.descripcion,
        columnas: JSON.parse(JSON.stringify(data.columnas))
      }
      this.dialogs.update = true
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.update))
        this.axios.put(services.routes.catalogue, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Catálogo actualizado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    copy(id) {
      if (!id) {
        this.loading = true
        let id = this.copied
        let data = { idcatalogo: id }
        this.axios.post(services.routes.catalogue + '/copy', { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Catálogo copiado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.copied = id
        this.dialogs.copy = true
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true
        let id = this.deleted
        this.axios.delete(services.routes.catalogue, { data: { data: { idcatalogo: this.deleted } } })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Catálogo eliminado exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.deleted = id
        this.dialogs.remove = true
      }
    },
    removeColumn() {
      if (this.dialogs.new) {
        this.forms.new.columnas.pop()
      }
      else if (this.dialogs.update) {
        this.forms.update.columnas.pop()
      }
    },
    addColumn() {
      if (this.dialogs.new) {
        this.forms.new.columnas.push({
          id: '',
          nombre: '',
          tipo: ''
        })
      }
      else if (this.dialogs.update) {
        this.forms.update.columnas.push({
          id: '',
          nombre: '',
          tipo: ''
        })
      }
    },
    cancelDetail() {
      this.detail = {
        id: '',
        title: '',
        search: '',
        headers: [],
        items: [],
        selected: [],
        columns: [],
        values: []
      }
    },
    getDetail(data) {
      this.loading = true
      this.cancelDetail()
      this.detail.id = data.idcatalogo
      this.detail.title = data.nombre
      this.detail.columns = JSON.parse(JSON.stringify(data.columnas))
      for (let i = 0; i < data.columnas.length; i++) {
        this.detail.headers.push({
          text: data.columnas[i].nombre,
          align: 'left',
          sortable: false,
          value: data.columnas[i].id
        })
      }
      this.axios.get(services.routes.catalogue + '/detail?id=' + data.idcatalogo)
        .then(response => {
          if (response.data.data.valores) {
            for (let i = 0; i < response.data.data.valores.length; i++) {
              let row = {}
              for (let j = 0; j < data.columnas.length; j++) {
                row[data.columnas[j].id] = response.data.data.valores[i][j]
              }
              this.detail.items.push(row)
            }
            this.detail.values = JSON.parse(JSON.stringify(response.data.data.valores))
          }
        })
        .catch(error => {
          this.error = true
          this.msgError = 'La información no fue obtenida'
        })
        .finally(() => {
          this.loading = false
        })
    },
    accept() {
      if (this.forms.import.id) {
        let fr = new FileReader();
        let file = this.forms.import.id
        let size = this.forms.import.id.size
        if (size <= 5000000) {
          this.loading = true
          setTimeout(() => {
            fr.onload = () => {
              let data = XLSX.read(fr.result, { type: "array" })
              let sheetNames = data.SheetNames
              let worksheet = data.Sheets[sheetNames[0]]
              let csv = XLSX.utils.sheet_to_csv(worksheet).trim()
              let values = Papa.parse(csv)
              if (values.data && values.data.length) {
                values = values.data
                if (this.forms.import.header) {
                  values.shift()
                }
                this.showValues(values)
                this.cancel()
              }
              else {
                this.error = true
                this.msgError = 'El archivo no fue leído correctamente'
              }
              this.loading = false
            }
            fr.onerror = () => {
              this.loading = false
              this.forms.import.id = null
              this.error = true
              this.msgError = 'El archivo no fue leído correctamente'
            }
            fr.readAsArrayBuffer(file);
          }, 500);
        }
        else {
          this.error = true
          this.msgError = 'El archivo ha excedido el límite permitido (5 MB)'
        }
      }
      else {
        this.error = true
        this.msgError = 'Seleccionar archivo'
      }
    },
    showValues(data) {
      this.detail.items = []
      for (let i = 0; i < data.length; i++) {
        let row = {}
        for (let j = 0; j < this.detail.headers.length; j++) {
          row[this.detail.headers[j].value] = data[i][j]
        }
        this.detail.items.push(row)
      }
      this.detail.values = JSON.parse(JSON.stringify(data))
    },
    checkData(type, value) {
      if (type === 'texto') {
        if (!_.isString(value) || value.length > 100) {
          return false
        }
      }
      else if (type === 'entero') {
        if (!(/^\d{1,6}$/.test(value) && parseInt(value) >= 0 && parseInt(value) <= 999999)) {
          return false
        }
      }
      else if (type === 'decimal') {
        if (!(/^\d{1,6}(\.\d{1,5})?$/.test(value) && parseFloat(value) >= 0 && parseFloat(parseFloat(value).toFixed(5)) <= 999999.99999)) {
          return false
        }
      }
      else {
        return false
      }
      return true
    },
    updateValues() {
      if (this.detail.values.length <= 500) {
        for (let i = 0; i < this.detail.values.length; i++) {
          for (let j = 0; j < this.detail.columns.length; j++) {
            if (!this.checkData(this.detail.columns[j].tipo, this.detail.values[i][j])) {
              this.error = true
              this.msgError = 'Error en la fila (' + (i + 1) + '), columna (' + (j + 1) + ')'
              return false
            }
          }
        }
        this.loading = true
        let data = {
          idcatalogo: this.detail.id,
          valores: JSON.parse(JSON.stringify(this.detail.values))
        }
        this.axios.put(services.routes.catalogue + '/value', { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Valores del catálogo actualizados exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'Las filas del catálogo superan el límite máximo (500)'
      }
    }
  }
}
</script>

<style scoped>
</style>
